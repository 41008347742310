<template>
    <div class="form-container">
        <!-- 작품 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">문의 작품</h3>
            </div>
            <div class="table-style table-style--default">
                <v-row align="center">
                    <v-col cols="auto">
                        <!-- <bids-item-imgs :imgs="work?.imgs || []" /> -->
                    </v-col>
                    <v-col>
                        <div class="py-10 py-md-14">
                            <div>
                                <strong class="font-size-14 font-weight-medium">LOT. {{ work?.lot }}</strong>
                            </div>
                            <div class="mt-8 mt-md-10 ellip">
                                <strong class="font-size-20 font-size-md-24 font-weight-medium font-notoserif">{{ work?.author?.name?.ko }}</strong>
                            </div>
                            <div class="mt-md-2 ellip">
                                <span class="font-size-16 font-size-md-18 font-notoserif">{{ work?.subject }}</span>
                            </div>
                            <div class="mt-10 mt-md-12">
                                <span class="font-size-14 grey-68--text">{{ work?.year }} ㅣ {{ work?.material }} ㅣ {{ work?.dimensionTag }}</span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- 작품 정보 -->

        <!-- 문의자 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">문의자 정보</h3>
            </div>
            <div class="table-style table-style--default">
                <div class="table-style table-style--default">
                    <myinfo-modify-row label="이름">
                        {{ form.meta.name }}
                    </myinfo-modify-row>

                    <myinfo-modify-row label="연락처">
                        <div class="mw-lg-500px">
                            {{ form.meta.phone }}
                        </div>
                    </myinfo-modify-row>
                    <myinfo-modify-row label="이메일">
                        <div class="mw-lg-500px">
                            {{ form.meta.email }}
                        </div>
                    </myinfo-modify-row>
                </div>
            </div>
        </div>
        <!-- 문의자 정보 -->

        <!-- 문의 상세 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">문의 상세</h3>
            </div>
            <div class="table-style table-style--default">
                <myinfo-modify-row label="제목">
                    <v-text-field v-model="form.subject" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="내용">
                    <v-textarea v-model="form.content" outlined persistent-placeholder hide-details />
                </myinfo-modify-row>
            </div>
        </div>
        <!-- 문의 상세 -->

        <!-- 하단버튼 -->
        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">취소</span></v-btn>
                <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">문의하기</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import ImagesInput from "@/components/console/dumb/images-input.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";

import api from "@/api";

export default {
    components: {
        ImagesInput,
        MyinfoModifyRow,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            work: null,
            form: this.$props.value,
        };
    },
    computed: {
        _work() {
            return this.$route.query._work;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
    methods: {
        async init() {
            const { _work: _id } = this;
            const { work } = await api.v1.auction.works.get({ _id });
            this.work = work;
        },
    },
};
</script>
